<template>
  <div id="app">
    <router-view v-if="isReload"/>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload,
    }
  },
  data() {
    return {
      isReload: true,
      cur_hash: null,
      iMessage: false,
    }
  },
  mounted() {
    if(!localStorage.getItem('cur_hash_src')){
      localStorage.setItem('cur_hash_src','')
    }
    clearInterval(this.timerVersion)
    this.timerVersion = setInterval(this.getHash, 10*1000)
  },
  methods: {
    reload() {
      this.isReload = false;
      this.$nextTick(() => {
        this.isReload = true
      })
    },
    // 版本更新提示刷新页面
    async getHash() {
      // 在 js 中请求首页地址不会更新页面
      const timestamp = new Date().getTime();
      const response = await axios.get(`${window.location.protocol}//${window.location.host}/?t=${timestamp}`);
      // 返回的是字符串，需要转换为 html
      let el = document.createElement('html')
      el.innerHTML = response.data
      // 将 HTMLCollection 转换为数组
      const scriptsArray = Array.from(el.getElementsByTagName('script'));
      // 使用 filter 方法过滤出包含 'runtime' 的 script 元素
      const filteredScripts = scriptsArray.filter(item => item.src && item.src.includes('runtime'));
      let new_hash_src = filteredScripts[0].src.split('/')
      // 取出 hash 值
      let new_hash = new_hash_src[new_hash_src.length - 1].split('.')[1]
      // iMessage 是个全局变量（默认值 false），用来帮助判断什么时候会弹出提示，不然定时器5s就弹一次了
      if (new_hash !== localStorage.getItem('cur_hash_src') && !this.iMessage) {
        localStorage.setItem('cur_hash_src',new_hash)
        // 版本更新，弹出提示
        this.iMessage = true
        const h = this.$createElement;
        this.$message.warning({
          message:
              h('span', [
                `当前版本已更新，请刷新后使用`,
                h('a', {
                  on: {
                    click: function () {
                      window.location.reload(true)
                    }
                  },
                  style: 'color: #44cef6;padding-left:15px;cursor: pointer;'
                },'刷新页面')
              ]),
          duration: 0,
          closable: true
        });
      }
    }
  }
}
</script>
<style lang="scss">
.el-image-viewer__canvas img{
  margin: auto !important;
}
</style>

